import * as React from "react"
import { Top, Footer, Loading } from '@src/share-component'
import './404.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/common.css'
import Sky from '@src/asset/skyscraper.jpg'
import { Typography, useTheme } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-react-intl"
import { navigate } from 'gatsby'
import { Meta } from '@src/share-component'

const NotFoundPage = () => { 
  // const intl = useIntl()
  // return <div></div>
  // return navigate(`/${intl.locale}`) 
  const intl = useIntl()
  return (
    <React.Fragment>
    <Meta
      title = { intl.formatMessage({ id : "404-title" }) }
      keywords = { intl.formatMessage({ id : "404-keywords" }) }
      description = { intl.formatMessage({ id : "404-description" }) }
      slug = "/404/"
    />
      <div class = 'page'>
      <Top bg = {Sky} noSlogan = {true} useFullHeight = {true} imgID = 'notfound-img'/>

      <div class = 'notfound'>
        <Typography variant = 'h1' classes = {{ root : 'notfound-title', h1 : "notfound-title" }}>{intl.formatMessage({ id : 'notfound-title' })}</Typography>
      </div>
      <Footer/>
      </div>
    </React.Fragment>
  )
}

export default NotFoundPage
